<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">EWA Request List</div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Request Date" v-if="!$route.params.status">
                <el-date-picker
                  v-model="search.date"
                  type="daterange"
                  size="small"
                  :picker-options="datePickerOptions"
                  class="w-full"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input clearable size="small" type="text" v-model="search.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="ml-4 w-1/2" v-if="!$route.params.status">
            <el-form ref="form" label-width="150px">
              <el-form-item label="Approval Status">
                <el-select size="small" v-model="status_filter" placeholder="Approval Status" class="mr-2">
                  <el-option label="All" value=""/>
                  <el-option label="Waiting Approval" value="waiting_approval"/>
                  <el-option label="Approved" value="approved"/>
                  <el-option label="Rejected" value="rejected"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Disbursement Status">
                <el-select size="small" v-model="disbursement_status_filter" placeholder="Disbursement Status" class="mr-2">
                  <el-option label="All" value=""/>
                  <el-option label="Waiting Approval" value="waiting_approval"/>
                  <el-option label="Waiting Disbursement" value="waiting_disbursement"/>
                  <el-option label="Completed" value="completed"/>
                  <el-option label="Failed" value="failed"/>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        <el-button type="primary" @click="downloadHandler" size="small"><font-awesome-icon icon="download"/> Download</el-button>
      </div>
      <div class="table-responsive">
        <el-skeleton :loading="is_loading" :rows="6" animated />
        <table v-if="!is_loading" class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="30%">Request Date</th>
              <!-- del -->
              <th width="40%">Bank Account Name</th>
              <th width="40%">Bank Account Number</th>
              <th width="40%">Bank ID</th>
              <th width="30%">Requestor Name</th>
              <th>Amount</th>
              <th>Admin Fee</th>
              <th>HR Approval Status</th>
              <th>Disbursement Status</th>
              <th>Reference Number</th>
              <th>Disbursement Date</th>
              <th v-if="$route.params.status && $route.params.status != 'completed'">#</th>
            </tr>
          </thead>
          <tbody v-loading="is_loading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.created_at_str }}</td>
              <!-- del -->
              <td>{{ item.bank_account_name }}</td>
              <td>{{ item.bank_account_number }}</td>
              <td>{{ item.bank_id }}</td>
              <td>{{ item.requestor_name }}</td>
              <td>{{ item.request_amount_str }}</td>
              <td>{{ item.admin_fee_str }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <el-popover v-if="item.disburse_status == 'failed' && item.failed_reason"
                    placement="left"
                    width="300"
                    trigger="hover"
                    :content="item.failed_reason">
                      <span slot="reference" class="tag" :class="item.disburse_status_color">{{ item.disburse_status_str }}</span>
                  </el-popover>
                <span v-else class="tag" :class="item.disburse_status_color">{{ item.disburse_status_str }}</span>
              </td>
              <td>{{ item.reference_number || '-' }}</td>
              <td>{{ item.disburse_at_str }}</td>
              <td v-if="$route.params.status && $route.params.status != 'completed'">
                <el-button v-if="$route.params.status == 'waiting_approval'" round size="mini" @click="handlerApproveBtn(item, $route.params.status)" type="success">
                  <font-awesome-icon icon="check"/>
                </el-button>
                <el-button round size="mini" @click="handlerBtnReject(item)" type="danger">
                  <font-awesome-icon icon="times-circle"/>
                </el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="!total_rows && !is_loading" :image="emptyStateImage"></el-empty>
      </div>
      <b-modal size="md" v-model="show.modal_ewa" title="Approve EWA" hide-footer>
        <el-descriptions :loading="loading.modal_ewa" class="margin-top" title="" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Employee Name
            </template>
            {{ selected_ewa.requestor_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i><font-awesome-icon icon="users" size="sm"/></i>
              Total EWA Request
            </template>
            1
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i><font-awesome-icon icon="money-bill-alt" size="sm"/></i>
              Total EWA Amount
            </template>
            {{ selected_ewa.request_amount_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i><font-awesome-icon icon="hand-holding-usd" size="sm"/></i>
              Total Admin Fee
            </template>
            {{ selected_ewa.admin_fee_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i><font-awesome-icon icon="money-bill-wave" size="sm"/></i>
              Total Disburse Amount
            </template>
            Rp. {{ (selected_ewa.request_amount - selected_ewa.admin_fee).toLocaleString() }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="d-flex float-right mt-2">
          <el-button class="mr-2 ml-2" @click="submit_approval" size="small" type="primary">Submit</el-button>
          <el-button @click="show.modal_ewa = false" size="small" class="mr-2">Cancel</el-button>
        </div>
      </b-modal>
      <pagination
        :page="page"
        :per_page="per_page"
        :list="list"
        :total_rows="total_rows"
        @pageChange="pageChangeHandler"
        />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {
  GET_DETAIL_EWALIST, ROLLBACK_APPROVAL, INQUIRY_APPROVAL, SUBMIT_APPROVAL,
} from '@/store/modules/ewa';
import emptyStateImage from '@/assets/images/undraw/empty-approval-history.svg';
import Pagination from '../../components/Pagination.vue';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: { Pagination },
  name: 'DetailEwaCompany',
  data() {
    return {
      emptyStateImage,
      is_loading: false,
      total_rows: 0,
      page: 1,
      per_page: 20,
      list: [],
      show: {
        modal_ewa: false,
      },
      loading: {
        modal_ewa: false,
      },
      selected_ewa: {},
      status_filter: '',
      disbursement_status_filter: '',
      search: {
        search_keyword: '',
        search_by: 'full_name',
        date: [
          moment().subtract(1, 'month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        action: '',
      },
      search_options: [
        { value: 'full_name', text: 'Requestor Name' },
        { value: 'email', text: 'Requestor Email' },
        { value: 'phone_number', text: 'Requestor Phone Number' },
      ],
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
    };
  },
  metaInfo: {
    title: 'Early Wage Access',
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.is_loading = true;
      this.$parent.showLoader();
      if (this.$route.params.status) {
        delete this.search.date;
      }
      const q = {
        disburse_status_filter: this.$route.params.status ? this.$route.params.status : this.disbursement_status_filter,
        status_filter: this.$route.params.status ? this.$route.query.approval_status : this.status_filter,
        page: this.page,
        ...this.search,
      };
      if (q.disburse_status_filter === 'completed') {
        q.disburse_status_filter = ['completed', 'failed'];
      }
      await this.$store.dispatch(GET_DETAIL_EWALIST, {
        id: this.$route.params.id,
        query: q,
      }).then((data) => {
        if (this.search.action === 'download') {
          const anchor = document.createElement('a');
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
          anchor.target = '_blank';
          // anchor.download = `Earned_Wage_Report_${moment(this.search.date[0]).format('YYYY-MM-DD')}-${moment(this.search.date[1]).format('YYYY-MM-DD')}`;
          anchor.download = 'Earned_Wage_Report.csv';
          anchor.click();
        }
      });
      this.$parent.hideLoader();
      if (this.search.action !== 'download') {
        const { rows, count } = this.$store.getters.detail_ewa_list;
        this.list = rows;
        this.total_rows = count;
      }
      this.is_loading = false;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.search.action = '';
      this.pageChangeHandler(1);
    },
    downloadHandler() {
      this.search.action = 'download';
      this.pageChangeHandler(1);
    },
    handlerBtnReject(item) {
      const obj = {
        owned_by: item.owned_by,
      };
      this.$confirm('You reject this request. You can not undo this action. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(ROLLBACK_APPROVAL, {
              id: this.$route.params.id,
              current_state: this.$route.params.status,
              data: obj,
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Rejection success',
              });
              this.getList();
            }).catch((err) => {
              if (err.response?.data) popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {});
    },
    handlerApproveBtn(item, current_state) {
      this.show.modal_ewa = true;
      this.selected_ewa = item;
      this.selected_ewa.action = current_state === 'waiting_approval' ? 'waiting_disbursement' : 'disburse';
      this.selected_ewa.current_state = current_state;
      this.inquiry();
    },
    async inquiry() {
      this.loading.modal_ewa = true;
      const response = await this.$store.dispatch(INQUIRY_APPROVAL, {
        id: this.selected_ewa.company_id,
        query: {
          current_state: this.selected_ewa.current_state,
          owned_by: this.selected_ewa.owned_by.toString(),
          approval_type: 'single',
        },
      });
      this.selected_ewa.approval_id = response.approval_id;
      this.loading.modal_ewa = true;
    },
    async submit_approval() {
      this.$confirm('You will approve this EWA disbursement. You can not undo this action. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(SUBMIT_APPROVAL, {
              id: this.selected_ewa.company_id,
              data: {
                approval_id: this.selected_ewa.approval_id,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Approval success',
              });
              this.show.modal_ewa = false;
              this.getList();
            }).catch((err) => {
              if (err.response?.data) popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {});
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}
</style>